// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

export interface AddedToCalendar {
	event_ub_id: string;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
}

/**
 * This event signifies a user has submitted their shipping information.
 */
export interface CheckoutStarted {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * The category code of the order
	 */
	category: CategoryElement;
	/**
	 * The coupon name/code associated with the event.
	 */
	coupon?: string;
	/**
	 * Currency of the items associated with the event, in 3-letter ISO 4217 format.
	 */
	currency: string;
	discount?: number;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The main speciality of the product, if applicable.
	 */
	main_speciality?: string;
	/**
	 * Order/transaction ID
	 */
	order_id?: string;
	/**
	 * The payment method used for the order.
	 */
	payment_method?: string;
	products?: CheckoutStartedProduct[];
	/**
	 * The specialties which this order is associated with (if applicable).
	 */
	specialities?: string[];
	/**
	 * Tax cost associated with a transaction.
	 */
	tax?: number;
	/**
	 * Revenue (€) with discounts and coupons added in.
	 */
	total: number;
}

/**
 * Brand associated with the product
 */
export type BrandElement =
	| 'EXPERTEN_FORUM'
	| 'FORTBILDUNGSTAGE'
	| 'GUIDELINE'
	| 'ONDEMAND'
	| 'REFRESHER'
	| 'REFRESHER_ACTUELL'
	| 'SKILL'
	| 'STUDY_CONFERENCE_UPDATE'
	| 'SUMMEDUP'
	| 'SYROT'
	| 'UPDATE_REFRESHER'
	| 'WU660'
	| 'WUEX'
	| 'WUIF'
	| 'SYFAT';

/**
 * The category code of the order
 *
 * Product category being viewed
 *
 * Duplicate of category, used for Facebook destination
 *
 * Duplicate of category, used for passing to Facebook destination
 */
export type CategoryElement = 'COURSE' | 'DOCUMENT' | 'MEMBERSHIP' | 'TOPUP' | 'VIDEO' | 'WEBINAR';

export interface CheckoutStartedProduct {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main speciality of the item in the list
	 */
	main_speciality?: string;
	/**
	 * The title of product.
	 */
	name?: string;
	/**
	 * The position of the item in the list
	 */
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	/**
	 * The start datetime of the event ticket.
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

/**
 * For courses this is could ONLINE, HYBRID, ON_SITE
 */
export type Variant = 'HYBRID' | 'ONLINE' | 'ON_SITE';

/**
 * This event signifies a user has submitted their shipping information.
 */
export interface CheckoutStepCompleted {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * The category code of the order
	 */
	category: CategoryElement;
	/**
	 * The coupon name/code associated with the event.
	 */
	coupon?: string;
	/**
	 * Currency of the items associated with the event, in 3-letter ISO 4217 format.
	 */
	currency: string;
	discount?: number;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The main speciality of the product, if applicable.
	 */
	main_speciality?: string;
	/**
	 * Order/transaction ID
	 */
	order_id?: string;
	/**
	 * The payment method used for the order.
	 */
	payment_method?: string;
	products?: CheckoutStepCompletedProduct[];
	/**
	 * The shipping tier (e.g. Ground, Air, Next-day) selected for delivery of the purchased
	 * product.
	 */
	shipping_tier?: string;
	/**
	 * The specialties which this order is associated with (if applicable).
	 */
	specialities?: string[];
	step: CheckoutStepCompletedStep;
	/**
	 * Tax cost associated with a transaction.
	 */
	tax?: number;
	/**
	 * Revenue (€) with discounts and coupons added in.
	 */
	total: number;
	/**
	 * The monetary value of the event.
	 */
	value: number;
}

export interface CheckoutStepCompletedProduct {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main speciality of the item in the list
	 */
	main_speciality?: string;
	/**
	 * The title of product.
	 */
	name?: string;
	/**
	 * The position of the item in the list
	 */
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	/**
	 * The start datetime of the event ticket.
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

export type CheckoutStepCompletedStep =
	| 'BILLING_ADDRESS'
	| 'PAYMENT_METHOD'
	| 'PERSONAL_DATA'
	| 'SHIPPING_ADDRESS';

export interface DocumentThumbnailViewed {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for passing to Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * contentId of the product
	 */
	content_id?: string;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * Indicates if product has at least one partnership
	 */
	has_partnership?: boolean;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main specialty associated with the product.
	 */
	main_speciality?: string;
	/**
	 * The name of the product
	 */
	name?: string;
	partnerships?: DocumentThumbnailViewedPartnership[];
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	specialties: string[];
	/**
	 * The start datetime of the event ticket
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

export interface DocumentThumbnailViewedPartnership {
	partner_name?: string;
	partner_ub_account_code?: string;
}

export interface Login {
	/**
	 * Date and time of the login.
	 */
	date_time: Date;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
}

export interface OrderCompleted {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * The category code of the order
	 */
	category: CategoryElement;
	/**
	 * The coupon name/code associated with the event.
	 */
	coupon?: string;
	/**
	 * Currency of the items associated with the event, in 3-letter ISO 4217 format.
	 */
	currency: string;
	discount?: number;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The main speciality of the product, if applicable.
	 */
	main_speciality?: string;
	/**
	 * Order/transaction ID
	 */
	order_id?: string;
	/**
	 * The payment method used for the order.
	 */
	payment_method?: string;
	products?: OrderCompletedProduct[];
	/**
	 * The specialties which this order is associated with (if applicable).
	 */
	specialities?: string[];
	/**
	 * Tax cost associated with a transaction.
	 */
	tax?: number;
	/**
	 * Revenue (€) with discounts and coupons added in.
	 */
	total: number;
	/**
	 * Transaction ID associated with the order.
	 */
	transaction_id: string;
}

export interface OrderCompletedProduct {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main speciality of the item in the list
	 */
	main_speciality?: string;
	/**
	 * The title of product.
	 */
	name?: string;
	/**
	 * The position of the item in the list
	 */
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	/**
	 * The start datetime of the event ticket.
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

/**
 * This event signifies when one or more products is purchased by a user.
 */
export interface OrderStarted {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * The category code of the order
	 */
	category: CategoryElement;
	/**
	 * The coupon name/code associated with the event.
	 */
	coupon?: string;
	/**
	 * Currency of the items associated with the event, in 3-letter ISO 4217 format.
	 */
	currency: string;
	discount?: number;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The main speciality of the product, if applicable.
	 */
	main_speciality?: string;
	/**
	 * Order/transaction ID
	 */
	order_id?: string;
	/**
	 * The payment method used for the order.
	 */
	payment_method?: string;
	products?: OrderStartedProduct[];
	/**
	 * The specialties which this order is associated with (if applicable).
	 */
	specialities?: string[];
	/**
	 * Tax cost associated with a transaction.
	 */
	tax?: number;
	/**
	 * Revenue (€) with discounts and coupons added in.
	 */
	total: number;
}

export interface OrderStartedProduct {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main speciality of the item in the list
	 */
	main_speciality?: string;
	/**
	 * The title of product.
	 */
	name?: string;
	/**
	 * The position of the item in the list
	 */
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	/**
	 * The start datetime of the event ticket.
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

export interface OrderUpdated {
	/**
	 * The category code of the order
	 */
	category: OrderUpdatedCategory;
	/**
	 * The coupon name/code associated with the event.
	 */
	coupon?: string;
	/**
	 * Currency of the items associated with the event, in 3-letter ISO 4217 format.
	 */
	currency: string;
	discount?: number;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The main speciality of the product, if applicable.
	 */
	main_speciality?: string;
	/**
	 * Order/transaction ID
	 */
	order_id?: string;
	/**
	 * The payment method used for the order.
	 */
	payment_method?: string;
	products?: OrderUpdatedProduct[];
	/**
	 * The specialties which this order is associated with (if applicable).
	 */
	specialities?: string[];
	/**
	 * Tax cost associated with a transaction.
	 */
	tax?: number;
	/**
	 * Revenue (€) with discounts and coupons added in.
	 */
	total: number;
	/**
	 * Transaction ID associated with the order.
	 */
	transaction_id: string;
}

/**
 * The category code of the order
 *
 * Product category being viewed
 */
export type OrderUpdatedCategory = 'COURSE' | 'MEMBERSHIP' | 'TOPUP' | 'WEBINAR';

export interface OrderUpdatedProduct {
	/**
	 * Brand associated with the product
	 */
	brand?: PurpleBrand;
	/**
	 * Product category being viewed
	 */
	category: OrderUpdatedCategory;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main speciality of the item in the list
	 */
	main_speciality?: string;
	/**
	 * The title of product.
	 */
	name?: string;
	/**
	 * The position of the item in the list
	 */
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	/**
	 * The start datetime of the event ticket.
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

/**
 * Brand associated with the product
 */
export type PurpleBrand =
	| 'EXPERTEN_FORUM'
	| 'FORTBILDUNGSTAGE'
	| 'GUIDELINE'
	| 'REFRESHER'
	| 'REFRESHER_ACTUELL'
	| 'SKILL'
	| 'SUMMEDUP'
	| 'SYROT'
	| 'UPDATE_REFRESHER'
	| 'WU660'
	| 'WUEX'
	| 'WUIF';

export interface PageViewed {
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	login_status?: boolean;
	name?: string;
	page_category?: string;
	path?: string;
	referrer?: string;
	search?: string;
	sub_category?: string;
	title?: string;
	url?: string;
}

export interface ProductAdded {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for passing to Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * contentId of the product
	 */
	content_id?: string;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * Indicates if product has at least one partnership
	 */
	has_partnership?: boolean;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main specialty associated with the product.
	 */
	main_speciality?: string;
	/**
	 * The name of the product
	 */
	name?: string;
	partnerships?: ProductAddedPartnership[];
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	specialties: string[];
	/**
	 * The start datetime of the event ticket
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

export interface ProductAddedPartnership {
	partner_name?: string;
	partner_ub_account_code?: string;
}

export interface ProductClicked {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for passing to Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * contentId of the product
	 */
	content_id?: string;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * Indicates if product has at least one partnership
	 */
	has_partnership?: boolean;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main specialty associated with the product.
	 */
	main_speciality?: string;
	/**
	 * The name of the product
	 */
	name?: string;
	partnerships?: ProductClickedPartnership[];
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	specialties: string[];
	/**
	 * The start datetime of the event ticket
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

export interface ProductClickedPartnership {
	partner_name?: string;
	partner_ub_account_code?: string;
}

/**
 * Log this event when the user has been presented with a list of products of a certain
 * category.
 */
export interface ProductListViewed {
	brands?: BrandElement[];
	/**
	 * The categories of product in this list. Could be one of COURSE, WEBINAR, MEMBERSHIP, TOPUP
	 */
	categories: CategoryElement[];
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	/**
	 * The ID of the list in which the product was presented to the user.
	 */
	list_id?: string;
	locale?: string;
	products?: ProductListViewedProduct[];
	specialities?: string[];
}

export interface ProductListViewedProduct {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main speciality of the item in the list
	 */
	main_speciality?: string;
	/**
	 * The title of product.
	 */
	name?: string;
	/**
	 * The position of the item in the list
	 */
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	/**
	 * The start datetime of the event ticket.
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

export interface ProductViewed {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for passing to Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * contentId of the product
	 */
	content_id?: string;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * Indicates if product has at least one partnership
	 */
	has_partnership?: boolean;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main specialty associated with the product.
	 */
	main_speciality?: string;
	/**
	 * The name of the product
	 */
	name?: string;
	partnerships?: ProductViewedPartnership[];
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	specialties: string[];
	/**
	 * The start datetime of the event ticket
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

export interface ProductViewedPartnership {
	partner_name?: string;
	partner_ub_account_code?: string;
}

/**
 * This event signifies a promotion was selected from a list.
 */
export interface PromotionClicked {
	/**
	 * The name of the promotional creative.
	 */
	creative_name: string;
	/**
	 * The name of the promotional creative slot associated with the event.
	 */
	creative_slot: string;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	products?: PromotionClickedProduct[];
	/**
	 * The ID of the promotion associated with the event.
	 */
	promotion_id: string;
	/**
	 * The name of the promotion associated with the event.
	 */
	promotion_name: string;
}

export interface PromotionClickedProduct {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main speciality of the item in the list
	 */
	main_speciality?: string;
	/**
	 * The title of product.
	 */
	name?: string;
	/**
	 * The position of the item in the list
	 */
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	/**
	 * The start datetime of the event ticket.
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

/**
 * This event signifies a promotion was viewed from a list.
 */
export interface PromotionViewed {
	/**
	 * The name of the promotional creative.
	 */
	creative_name: string;
	/**
	 * The name of the promotional creative slot associated with the event.
	 */
	creative_slot: string;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	products?: PromotionViewedProduct[];
	/**
	 * The ID of the promotion associated with the event.
	 */
	promotion_id: string;
	/**
	 * The name of the promotion associated with the event.
	 */
	promotion_name: string;
}

export interface PromotionViewedProduct {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main speciality of the item in the list
	 */
	main_speciality?: string;
	/**
	 * The title of product.
	 */
	name?: string;
	/**
	 * The position of the item in the list
	 */
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	/**
	 * The start datetime of the event ticket.
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

/**
 * DO NOT EDIT. Event generated to store end users consent preferences for Unify and Twilio
 * Engage.
 */
export interface SegmentConsentPreferenceUpdated {
	/**
	 * This property is added as a workaround to generate type for events without properties
	 */
	_?: null;
}

export interface SignupCompleted {
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The source
	 */
	source?: string;
}

export interface SignupStarted {
	email: string;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * where did the signup initiated from.
	 */
	source?: string;
}

export interface SignupStepCompleted {
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	step: SignupStepCompletedStep;
}

export type SignupStepCompletedStep = 'MEDICAL_DATA' | 'PERSONAL_DATA';

export interface VideoThumbnailViewed {
	/**
	 * Brand associated with the product
	 */
	brand?: BrandElement;
	/**
	 * Product category being viewed
	 */
	category: CategoryElement;
	/**
	 * Duplicate of category, used for passing to Facebook destination
	 */
	content_category?: CategoryElement;
	/**
	 * contentId of the product
	 */
	content_id?: string;
	/**
	 * The end datetime of the event ticket
	 */
	end_datetime?: Date;
	/**
	 * Indicates if product has at least one partnership
	 */
	has_partnership?: boolean;
	/**
	 * Hashed email of user if available
	 */
	hashed_e?: string;
	isLoggedIn?: boolean;
	locale?: string;
	/**
	 * The primary location in which this product is present. For courses, this could be the
	 * location in which the course is happening. For membership this is the country in which
	 * the membership is valid for.
	 */
	location: string;
	/**
	 * The main specialty associated with the product.
	 */
	main_speciality?: string;
	/**
	 * The name of the product
	 */
	name?: string;
	partnerships?: VideoThumbnailViewedPartnership[];
	position?: number;
	/**
	 * The local price which is bounded to country specific currency.
	 */
	price: number;
	/**
	 * The closest property of product to sizing. For events could day ticket or entire event,
	 * for membership should it should be the cycle, for TopUp it should be the amount.
	 */
	sku?: string;
	specialties: string[];
	/**
	 * The start datetime of the event ticket
	 */
	start_datetime?: Date;
	/**
	 * Product detailed page. For membership we should use the membership landing page.
	 */
	url?: string;
	/**
	 * For courses this is could ONLINE, HYBRID, ON_SITE
	 */
	variant: Variant;
}

export interface VideoThumbnailViewedPartnership {
	partner_name?: string;
	partner_ub_account_code?: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
	public static toAddedToCalendar(json: string): AddedToCalendar {
		return cast(JSON.parse(json), r('AddedToCalendar'));
	}

	public static addedToCalendarToJson(value: AddedToCalendar): string {
		return JSON.stringify(uncast(value, r('AddedToCalendar')), null, 2);
	}

	public static toCheckoutStarted(json: string): CheckoutStarted {
		return cast(JSON.parse(json), r('CheckoutStarted'));
	}

	public static checkoutStartedToJson(value: CheckoutStarted): string {
		return JSON.stringify(uncast(value, r('CheckoutStarted')), null, 2);
	}

	public static toCheckoutStepCompleted(json: string): CheckoutStepCompleted {
		return cast(JSON.parse(json), r('CheckoutStepCompleted'));
	}

	public static checkoutStepCompletedToJson(value: CheckoutStepCompleted): string {
		return JSON.stringify(uncast(value, r('CheckoutStepCompleted')), null, 2);
	}

	public static toDocumentThumbnailViewed(json: string): DocumentThumbnailViewed {
		return cast(JSON.parse(json), r('DocumentThumbnailViewed'));
	}

	public static documentThumbnailViewedToJson(value: DocumentThumbnailViewed): string {
		return JSON.stringify(uncast(value, r('DocumentThumbnailViewed')), null, 2);
	}

	public static toLogin(json: string): Login {
		return cast(JSON.parse(json), r('Login'));
	}

	public static loginToJson(value: Login): string {
		return JSON.stringify(uncast(value, r('Login')), null, 2);
	}

	public static toOrderCompleted(json: string): OrderCompleted {
		return cast(JSON.parse(json), r('OrderCompleted'));
	}

	public static orderCompletedToJson(value: OrderCompleted): string {
		return JSON.stringify(uncast(value, r('OrderCompleted')), null, 2);
	}

	public static toOrderStarted(json: string): OrderStarted {
		return cast(JSON.parse(json), r('OrderStarted'));
	}

	public static orderStartedToJson(value: OrderStarted): string {
		return JSON.stringify(uncast(value, r('OrderStarted')), null, 2);
	}

	public static toOrderUpdated(json: string): OrderUpdated {
		return cast(JSON.parse(json), r('OrderUpdated'));
	}

	public static orderUpdatedToJson(value: OrderUpdated): string {
		return JSON.stringify(uncast(value, r('OrderUpdated')), null, 2);
	}

	public static toPageViewed(json: string): PageViewed {
		return cast(JSON.parse(json), r('PageViewed'));
	}

	public static pageViewedToJson(value: PageViewed): string {
		return JSON.stringify(uncast(value, r('PageViewed')), null, 2);
	}

	public static toProductAdded(json: string): ProductAdded {
		return cast(JSON.parse(json), r('ProductAdded'));
	}

	public static productAddedToJson(value: ProductAdded): string {
		return JSON.stringify(uncast(value, r('ProductAdded')), null, 2);
	}

	public static toProductClicked(json: string): ProductClicked {
		return cast(JSON.parse(json), r('ProductClicked'));
	}

	public static productClickedToJson(value: ProductClicked): string {
		return JSON.stringify(uncast(value, r('ProductClicked')), null, 2);
	}

	public static toProductListViewed(json: string): ProductListViewed {
		return cast(JSON.parse(json), r('ProductListViewed'));
	}

	public static productListViewedToJson(value: ProductListViewed): string {
		return JSON.stringify(uncast(value, r('ProductListViewed')), null, 2);
	}

	public static toProductViewed(json: string): ProductViewed {
		return cast(JSON.parse(json), r('ProductViewed'));
	}

	public static productViewedToJson(value: ProductViewed): string {
		return JSON.stringify(uncast(value, r('ProductViewed')), null, 2);
	}

	public static toPromotionClicked(json: string): PromotionClicked {
		return cast(JSON.parse(json), r('PromotionClicked'));
	}

	public static promotionClickedToJson(value: PromotionClicked): string {
		return JSON.stringify(uncast(value, r('PromotionClicked')), null, 2);
	}

	public static toPromotionViewed(json: string): PromotionViewed {
		return cast(JSON.parse(json), r('PromotionViewed'));
	}

	public static promotionViewedToJson(value: PromotionViewed): string {
		return JSON.stringify(uncast(value, r('PromotionViewed')), null, 2);
	}

	public static toSegmentConsentPreferenceUpdated(json: string): SegmentConsentPreferenceUpdated {
		return cast(JSON.parse(json), r('SegmentConsentPreferenceUpdated'));
	}

	public static segmentConsentPreferenceUpdatedToJson(
		value: SegmentConsentPreferenceUpdated
	): string {
		return JSON.stringify(uncast(value, r('SegmentConsentPreferenceUpdated')), null, 2);
	}

	public static toSignupCompleted(json: string): SignupCompleted {
		return cast(JSON.parse(json), r('SignupCompleted'));
	}

	public static signupCompletedToJson(value: SignupCompleted): string {
		return JSON.stringify(uncast(value, r('SignupCompleted')), null, 2);
	}

	public static toSignupStarted(json: string): SignupStarted {
		return cast(JSON.parse(json), r('SignupStarted'));
	}

	public static signupStartedToJson(value: SignupStarted): string {
		return JSON.stringify(uncast(value, r('SignupStarted')), null, 2);
	}

	public static toSignupStepCompleted(json: string): SignupStepCompleted {
		return cast(JSON.parse(json), r('SignupStepCompleted'));
	}

	public static signupStepCompletedToJson(value: SignupStepCompleted): string {
		return JSON.stringify(uncast(value, r('SignupStepCompleted')), null, 2);
	}

	public static toVideoThumbnailViewed(json: string): VideoThumbnailViewed {
		return cast(JSON.parse(json), r('VideoThumbnailViewed'));
	}

	public static videoThumbnailViewedToJson(value: VideoThumbnailViewed): string {
		return JSON.stringify(uncast(value, r('VideoThumbnailViewed')), null, 2);
	}
}

function invalidValue(typ: any, val: any, key: any = ''): never {
	if (key) {
		throw Error(
			`Invalid value for key "${key}". Expected type ${JSON.stringify(
				typ
			)} but got ${JSON.stringify(val)}`
		);
	}
	throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
	if (typ.jsonToJS === undefined) {
		const map: any = {};
		typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
		typ.jsonToJS = map;
	}
	return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
	if (typ.jsToJSON === undefined) {
		const map: any = {};
		typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
		typ.jsToJSON = map;
	}
	return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
	function transformPrimitive(typ: string, val: any): any {
		if (typeof typ === typeof val) return val;
		return invalidValue(typ, val, key);
	}

	function transformUnion(typs: any[], val: any): any {
		// val must validate against one typ in typs
		const l = typs.length;
		for (let i = 0; i < l; i++) {
			const typ = typs[i];
			try {
				return transform(val, typ, getProps);
			} catch (_) {}
		}
		return invalidValue(typs, val);
	}

	function transformEnum(cases: string[], val: any): any {
		if (cases.indexOf(val) !== -1) return val;
		return invalidValue(cases, val);
	}

	function transformArray(typ: any, val: any): any {
		// val must be an array with no invalid elements
		if (!Array.isArray(val)) return invalidValue('array', val);
		return val.map((el) => transform(el, typ, getProps));
	}

	function transformDate(val: any): any {
		if (val === null) {
			return null;
		}
		const d = new Date(val);
		if (isNaN(d.valueOf())) {
			return invalidValue('Date', val);
		}
		return d;
	}

	function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
		if (val === null || typeof val !== 'object' || Array.isArray(val)) {
			return invalidValue('object', val);
		}
		const result: any = {};
		Object.getOwnPropertyNames(props).forEach((key) => {
			const prop = props[key];
			const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
			result[prop.key] = transform(v, prop.typ, getProps, prop.key);
		});
		Object.getOwnPropertyNames(val).forEach((key) => {
			if (!Object.prototype.hasOwnProperty.call(props, key)) {
				result[key] = transform(val[key], additional, getProps, key);
			}
		});
		return result;
	}

	if (typ === 'any') return val;
	if (typ === null) {
		if (val === null) return val;
		return invalidValue(typ, val);
	}
	if (typ === false) return invalidValue(typ, val);
	while (typeof typ === 'object' && typ.ref !== undefined) {
		typ = typeMap[typ.ref];
	}
	if (Array.isArray(typ)) return transformEnum(typ, val);
	if (typeof typ === 'object') {
		return typ.hasOwnProperty('unionMembers')
			? transformUnion(typ.unionMembers, val)
			: typ.hasOwnProperty('arrayItems')
				? transformArray(typ.arrayItems, val)
				: typ.hasOwnProperty('props')
					? transformObject(getProps(typ), typ.additional, val)
					: invalidValue(typ, val);
	}
	// Numbers can be parsed by Date but shouldn't be.
	if (typ === Date && typeof val !== 'number') return transformDate(val);
	return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
	return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
	return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
	return { arrayItems: typ };
}

function u(...typs: any[]) {
	return { unionMembers: typs };
}

function o(props: any[], additional: any) {
	return { props, additional };
}

function m(additional: any) {
	return { props: [], additional };
}

function r(name: string) {
	return { ref: name };
}

const typeMap: any = {
	AddedToCalendar: o(
		[
			{ json: 'event_ub_id', js: 'event_ub_id', typ: '' },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') }
		],
		'any'
	),
	CheckoutStarted: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{ json: 'coupon', js: 'coupon', typ: u(undefined, '') },
			{ json: 'currency', js: 'currency', typ: '' },
			{ json: 'discount', js: 'discount', typ: u(undefined, 3.14) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'order_id', js: 'order_id', typ: u(undefined, '') },
			{ json: 'payment_method', js: 'payment_method', typ: u(undefined, '') },
			{ json: 'products', js: 'products', typ: u(undefined, a(r('CheckoutStartedProduct'))) },
			{ json: 'specialities', js: 'specialities', typ: u(undefined, a('')) },
			{ json: 'tax', js: 'tax', typ: u(undefined, 3.14) },
			{ json: 'total', js: 'total', typ: 3.14 }
		],
		'any'
	),
	CheckoutStartedProduct: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{ json: 'position', js: 'position', typ: u(undefined, 0) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	CheckoutStepCompleted: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{ json: 'coupon', js: 'coupon', typ: u(undefined, '') },
			{ json: 'currency', js: 'currency', typ: '' },
			{ json: 'discount', js: 'discount', typ: u(undefined, 3.14) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'order_id', js: 'order_id', typ: u(undefined, '') },
			{ json: 'payment_method', js: 'payment_method', typ: u(undefined, '') },
			{
				json: 'products',
				js: 'products',
				typ: u(undefined, a(r('CheckoutStepCompletedProduct')))
			},
			{ json: 'shipping_tier', js: 'shipping_tier', typ: u(undefined, '') },
			{ json: 'specialities', js: 'specialities', typ: u(undefined, a('')) },
			{ json: 'step', js: 'step', typ: r('CheckoutStepCompletedStep') },
			{ json: 'tax', js: 'tax', typ: u(undefined, 3.14) },
			{ json: 'total', js: 'total', typ: 3.14 },
			{ json: 'value', js: 'value', typ: 3.14 }
		],
		'any'
	),
	CheckoutStepCompletedProduct: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{ json: 'position', js: 'position', typ: u(undefined, 0) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	DocumentThumbnailViewed: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'content_id', js: 'content_id', typ: u(undefined, '') },
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'has_partnership', js: 'has_partnership', typ: u(undefined, true) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{
				json: 'partnerships',
				js: 'partnerships',
				typ: u(undefined, a(r('DocumentThumbnailViewedPartnership')))
			},
			{ json: 'position', js: 'position', typ: u(undefined, 3.14) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'specialties', js: 'specialties', typ: a('') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	DocumentThumbnailViewedPartnership: o(
		[
			{ json: 'partner_name', js: 'partner_name', typ: u(undefined, '') },
			{
				json: 'partner_ub_account_code',
				js: 'partner_ub_account_code',
				typ: u(undefined, '')
			}
		],
		'any'
	),
	Login: o(
		[
			{ json: 'date_time', js: 'date_time', typ: Date },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') }
		],
		'any'
	),
	OrderCompleted: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{ json: 'coupon', js: 'coupon', typ: u(undefined, '') },
			{ json: 'currency', js: 'currency', typ: '' },
			{ json: 'discount', js: 'discount', typ: u(undefined, 3.14) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'order_id', js: 'order_id', typ: u(undefined, '') },
			{ json: 'payment_method', js: 'payment_method', typ: u(undefined, '') },
			{ json: 'products', js: 'products', typ: u(undefined, a(r('OrderCompletedProduct'))) },
			{ json: 'specialities', js: 'specialities', typ: u(undefined, a('')) },
			{ json: 'tax', js: 'tax', typ: u(undefined, 3.14) },
			{ json: 'total', js: 'total', typ: 3.14 },
			{ json: 'transaction_id', js: 'transaction_id', typ: '' }
		],
		'any'
	),
	OrderCompletedProduct: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{ json: 'position', js: 'position', typ: u(undefined, 0) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	OrderStarted: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{ json: 'coupon', js: 'coupon', typ: u(undefined, '') },
			{ json: 'currency', js: 'currency', typ: '' },
			{ json: 'discount', js: 'discount', typ: u(undefined, 3.14) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'order_id', js: 'order_id', typ: u(undefined, '') },
			{ json: 'payment_method', js: 'payment_method', typ: u(undefined, '') },
			{ json: 'products', js: 'products', typ: u(undefined, a(r('OrderStartedProduct'))) },
			{ json: 'specialities', js: 'specialities', typ: u(undefined, a('')) },
			{ json: 'tax', js: 'tax', typ: u(undefined, 3.14) },
			{ json: 'total', js: 'total', typ: 3.14 }
		],
		'any'
	),
	OrderStartedProduct: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{ json: 'position', js: 'position', typ: u(undefined, 0) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	OrderUpdated: o(
		[
			{ json: 'category', js: 'category', typ: r('OrderUpdatedCategory') },
			{ json: 'coupon', js: 'coupon', typ: u(undefined, '') },
			{ json: 'currency', js: 'currency', typ: '' },
			{ json: 'discount', js: 'discount', typ: u(undefined, 3.14) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'order_id', js: 'order_id', typ: u(undefined, '') },
			{ json: 'payment_method', js: 'payment_method', typ: u(undefined, '') },
			{ json: 'products', js: 'products', typ: u(undefined, a(r('OrderUpdatedProduct'))) },
			{ json: 'specialities', js: 'specialities', typ: u(undefined, a('')) },
			{ json: 'tax', js: 'tax', typ: u(undefined, 3.14) },
			{ json: 'total', js: 'total', typ: 3.14 },
			{ json: 'transaction_id', js: 'transaction_id', typ: '' }
		],
		'any'
	),
	OrderUpdatedProduct: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('PurpleBrand')) },
			{ json: 'category', js: 'category', typ: r('OrderUpdatedCategory') },
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{ json: 'position', js: 'position', typ: u(undefined, 0) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	PageViewed: o(
		[
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'login_status', js: 'login_status', typ: u(undefined, true) },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{ json: 'page_category', js: 'page_category', typ: u(undefined, '') },
			{ json: 'path', js: 'path', typ: u(undefined, '') },
			{ json: 'referrer', js: 'referrer', typ: u(undefined, '') },
			{ json: 'search', js: 'search', typ: u(undefined, '') },
			{ json: 'sub_category', js: 'sub_category', typ: u(undefined, '') },
			{ json: 'title', js: 'title', typ: u(undefined, '') },
			{ json: 'url', js: 'url', typ: u(undefined, '') }
		],
		'any'
	),
	ProductAdded: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'content_id', js: 'content_id', typ: u(undefined, '') },
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'has_partnership', js: 'has_partnership', typ: u(undefined, true) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{
				json: 'partnerships',
				js: 'partnerships',
				typ: u(undefined, a(r('ProductAddedPartnership')))
			},
			{ json: 'position', js: 'position', typ: u(undefined, 3.14) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'specialties', js: 'specialties', typ: a('') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	ProductAddedPartnership: o(
		[
			{ json: 'partner_name', js: 'partner_name', typ: u(undefined, '') },
			{
				json: 'partner_ub_account_code',
				js: 'partner_ub_account_code',
				typ: u(undefined, '')
			}
		],
		'any'
	),
	ProductClicked: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'content_id', js: 'content_id', typ: u(undefined, '') },
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'has_partnership', js: 'has_partnership', typ: u(undefined, true) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{
				json: 'partnerships',
				js: 'partnerships',
				typ: u(undefined, a(r('ProductClickedPartnership')))
			},
			{ json: 'position', js: 'position', typ: u(undefined, 3.14) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'specialties', js: 'specialties', typ: a('') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	ProductClickedPartnership: o(
		[
			{ json: 'partner_name', js: 'partner_name', typ: u(undefined, '') },
			{
				json: 'partner_ub_account_code',
				js: 'partner_ub_account_code',
				typ: u(undefined, '')
			}
		],
		'any'
	),
	ProductListViewed: o(
		[
			{ json: 'brands', js: 'brands', typ: u(undefined, a(r('BrandElement'))) },
			{ json: 'categories', js: 'categories', typ: a(r('CategoryElement')) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'list_id', js: 'list_id', typ: u(undefined, '') },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{
				json: 'products',
				js: 'products',
				typ: u(undefined, a(r('ProductListViewedProduct')))
			},
			{ json: 'specialities', js: 'specialities', typ: u(undefined, a('')) }
		],
		'any'
	),
	ProductListViewedProduct: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{ json: 'position', js: 'position', typ: u(undefined, 0) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	ProductViewed: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'content_id', js: 'content_id', typ: u(undefined, '') },
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'has_partnership', js: 'has_partnership', typ: u(undefined, true) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{
				json: 'partnerships',
				js: 'partnerships',
				typ: u(undefined, a(r('ProductViewedPartnership')))
			},
			{ json: 'position', js: 'position', typ: u(undefined, 3.14) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'specialties', js: 'specialties', typ: a('') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	ProductViewedPartnership: o(
		[
			{ json: 'partner_name', js: 'partner_name', typ: u(undefined, '') },
			{
				json: 'partner_ub_account_code',
				js: 'partner_ub_account_code',
				typ: u(undefined, '')
			}
		],
		'any'
	),
	PromotionClicked: o(
		[
			{ json: 'creative_name', js: 'creative_name', typ: '' },
			{ json: 'creative_slot', js: 'creative_slot', typ: '' },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{
				json: 'products',
				js: 'products',
				typ: u(undefined, a(r('PromotionClickedProduct')))
			},
			{ json: 'promotion_id', js: 'promotion_id', typ: '' },
			{ json: 'promotion_name', js: 'promotion_name', typ: '' }
		],
		'any'
	),
	PromotionClickedProduct: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{ json: 'position', js: 'position', typ: u(undefined, 0) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	PromotionViewed: o(
		[
			{ json: 'creative_name', js: 'creative_name', typ: '' },
			{ json: 'creative_slot', js: 'creative_slot', typ: '' },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'products', js: 'products', typ: u(undefined, a(r('PromotionViewedProduct'))) },
			{ json: 'promotion_id', js: 'promotion_id', typ: '' },
			{ json: 'promotion_name', js: 'promotion_name', typ: '' }
		],
		'any'
	),
	PromotionViewedProduct: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{ json: 'position', js: 'position', typ: u(undefined, 0) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	SegmentConsentPreferenceUpdated: o([{ json: '_', js: '_', typ: u(undefined, null) }], 'any'),
	SignupCompleted: o(
		[
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'source', js: 'source', typ: u(undefined, '') }
		],
		'any'
	),
	SignupStarted: o(
		[
			{ json: 'email', js: 'email', typ: '' },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'source', js: 'source', typ: u(undefined, '') }
		],
		'any'
	),
	SignupStepCompleted: o(
		[
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'step', js: 'step', typ: r('SignupStepCompletedStep') }
		],
		'any'
	),
	VideoThumbnailViewed: o(
		[
			{ json: 'brand', js: 'brand', typ: u(undefined, r('BrandElement')) },
			{ json: 'category', js: 'category', typ: r('CategoryElement') },
			{
				json: 'content_category',
				js: 'content_category',
				typ: u(undefined, r('CategoryElement'))
			},
			{ json: 'content_id', js: 'content_id', typ: u(undefined, '') },
			{ json: 'end_datetime', js: 'end_datetime', typ: u(undefined, Date) },
			{ json: 'has_partnership', js: 'has_partnership', typ: u(undefined, true) },
			{ json: 'hashed_e', js: 'hashed_e', typ: u(undefined, '') },
			{ json: 'isLoggedIn', js: 'isLoggedIn', typ: u(undefined, true) },
			{ json: 'locale', js: 'locale', typ: u(undefined, '') },
			{ json: 'location', js: 'location', typ: '' },
			{ json: 'main_speciality', js: 'main_speciality', typ: u(undefined, '') },
			{ json: 'name', js: 'name', typ: u(undefined, '') },
			{
				json: 'partnerships',
				js: 'partnerships',
				typ: u(undefined, a(r('VideoThumbnailViewedPartnership')))
			},
			{ json: 'position', js: 'position', typ: u(undefined, 3.14) },
			{ json: 'price', js: 'price', typ: 3.14 },
			{ json: 'sku', js: 'sku', typ: u(undefined, '') },
			{ json: 'specialties', js: 'specialties', typ: a('') },
			{ json: 'start_datetime', js: 'start_datetime', typ: u(undefined, Date) },
			{ json: 'url', js: 'url', typ: u(undefined, '') },
			{ json: 'variant', js: 'variant', typ: r('Variant') }
		],
		'any'
	),
	VideoThumbnailViewedPartnership: o(
		[
			{ json: 'partner_name', js: 'partner_name', typ: u(undefined, '') },
			{
				json: 'partner_ub_account_code',
				js: 'partner_ub_account_code',
				typ: u(undefined, '')
			}
		],
		'any'
	),
	BrandElement: [
		'EXPERTEN_FORUM',
		'FORTBILDUNGSTAGE',
		'GUIDELINE',
		'ONDEMAND',
		'REFRESHER',
		'REFRESHER_ACTUELL',
		'SKILL',
		'STUDY_CONFERENCE_UPDATE',
		'SUMMEDUP',
		'SYROT',
		'UPDATE_REFRESHER',
		'WU660',
		'WUEX',
		'WUIF'
	],
	CategoryElement: ['COURSE', 'DOCUMENT', 'MEMBERSHIP', 'TOPUP', 'VIDEO', 'WEBINAR'],
	Variant: ['HYBRID', 'ON_SITE', 'ONLINE'],
	CheckoutStepCompletedStep: [
		'BILLING_ADDRESS',
		'PAYMENT_METHOD',
		'PERSONAL_DATA',
		'SHIPPING_ADDRESS'
	],
	OrderUpdatedCategory: ['COURSE', 'MEMBERSHIP', 'TOPUP', 'WEBINAR'],
	PurpleBrand: [
		'EXPERTEN_FORUM',
		'FORTBILDUNGSTAGE',
		'GUIDELINE',
		'REFRESHER',
		'REFRESHER_ACTUELL',
		'SKILL',
		'SUMMEDUP',
		'SYROT',
		'UPDATE_REFRESHER',
		'WU660',
		'WUEX',
		'WUIF'
	],
	SignupStepCompletedStep: ['MEDICAL_DATA', 'PERSONAL_DATA']
};

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this:
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next'
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 *
 * setTypewriterOptions({ analytics: analytics })
 */
import type {
	AnalyticsSnippet,
	Analytics,
	AnalyticsBrowser,
	Options
} from '@segment/analytics-next';

declare global {
	interface Window {
		analytics: AnalyticsSnippet;
	}
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser;

/** The callback exposed by analytics.js. */
export type Callback = () => void;

export type ViolationHandler = (message: Record<string, any>, violations: any[]) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations
		},
		undefined,
		2
	);

	console.warn(msg);
};

let analytics: () => AnyAnalytics | undefined = () => {
	return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: AnyAnalytics;
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '9.1.0'
			}
		}
	};
}

/**
 * Fires a 'AddedToCalendar' track call.
 *
 * @param AddedToCalendar props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addedToCalendar(
	props: AddedToCalendar,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Added To Calendar',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'CheckoutStarted' track call.
 *
 * @param CheckoutStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function checkoutStarted(
	props: CheckoutStarted,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Checkout Started',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'CheckoutStepCompleted' track call.
 *
 * @param CheckoutStepCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function checkoutStepCompleted(
	props: CheckoutStepCompleted,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Checkout Step Completed',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'DocumentThumbnailViewed' track call.
 *
 * @param DocumentThumbnailViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function documentThumbnailViewed(
	props: DocumentThumbnailViewed,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Document Thumbnail Viewed',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'Login' track call.
 *
 * @param Login props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function login(props: Login, options?: Options, callback?: Callback): void {
	const a = analytics();
	if (a) {
		a.track(
			'Login',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'OrderCompleted' track call.
 *
 * @param OrderCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderCompleted(
	props: OrderCompleted,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Order Completed',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'OrderStarted' track call.
 *
 * @param OrderStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderStarted(props: OrderStarted, options?: Options, callback?: Callback): void {
	const a = analytics();
	if (a) {
		a.track(
			'Order Started',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'OrderUpdated' track call.
 *
 * @param OrderUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderUpdated(props: OrderUpdated, options?: Options, callback?: Callback): void {
	const a = analytics();
	if (a) {
		a.track(
			'Order Updated',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'PageViewed' track call.
 *
 * @param PageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pageViewed(props: PageViewed, options?: Options, callback?: Callback): void {
	const a = analytics();
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'ProductAdded' track call.
 *
 * @param ProductAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productAdded(props: ProductAdded, options?: Options, callback?: Callback): void {
	const a = analytics();
	if (a) {
		a.track(
			'Product Added',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'ProductClicked' track call.
 *
 * @param ProductClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productClicked(
	props: ProductClicked,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Product Clicked',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'ProductListViewed' track call.
 *
 * @param ProductListViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productListViewed(
	props: ProductListViewed,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Product List Viewed',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'ProductViewed' track call.
 *
 * @param ProductViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productViewed(props: ProductViewed, options?: Options, callback?: Callback): void {
	const a = analytics();
	if (a) {
		a.track(
			'Product Viewed',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'PromotionClicked' track call.
 *
 * @param PromotionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function promotionClicked(
	props: PromotionClicked,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Promotion Clicked',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'PromotionViewed' track call.
 *
 * @param PromotionViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function promotionViewed(
	props: PromotionViewed,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Promotion Viewed',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'SegmentConsentPreferenceUpdated' track call.
 *
 * @param SegmentConsentPreferenceUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function segmentConsentPreferenceUpdated(
	props: SegmentConsentPreferenceUpdated,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Segment Consent Preference Updated',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'SignupCompleted' track call.
 *
 * @param SignupCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signupCompleted(
	props: SignupCompleted,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Signup Completed',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'SignupStarted' track call.
 *
 * @param SignupStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signupStarted(props: SignupStarted, options?: Options, callback?: Callback): void {
	const a = analytics();
	if (a) {
		a.track(
			'Signup Started',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'SignupStepCompleted' track call.
 *
 * @param SignupStepCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signupStepCompleted(
	props: SignupStepCompleted,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Signup Step Completed',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}
/**
 * Fires a 'VideoThumbnailViewed' track call.
 *
 * @param VideoThumbnailViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function videoThumbnailViewed(
	props: VideoThumbnailViewed,
	options?: Options,
	callback?: Callback
): void {
	const a = analytics();
	if (a) {
		a.track(
			'Video Thumbnail Viewed',
			props || {},
			{
				...options,
				context: {
					...(options?.context || {}),
					typewriter: {
						language: 'typescript',
						version: ''
					}
				}
			},
			callback
		);
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,

	/**
	 * Fires a 'AddedToCalendar' track call.
	 *
	 * @param AddedToCalendar props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	addedToCalendar,
	/**
	 * Fires a 'CheckoutStarted' track call.
	 *
	 * @param CheckoutStarted props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	checkoutStarted,
	/**
	 * Fires a 'CheckoutStepCompleted' track call.
	 *
	 * @param CheckoutStepCompleted props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	checkoutStepCompleted,
	/**
	 * Fires a 'DocumentThumbnailViewed' track call.
	 *
	 * @param DocumentThumbnailViewed props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	documentThumbnailViewed,
	/**
	 * Fires a 'Login' track call.
	 *
	 * @param Login props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	login,
	/**
	 * Fires a 'OrderCompleted' track call.
	 *
	 * @param OrderCompleted props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	orderCompleted,
	/**
	 * Fires a 'OrderStarted' track call.
	 *
	 * @param OrderStarted props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	orderStarted,
	/**
	 * Fires a 'OrderUpdated' track call.
	 *
	 * @param OrderUpdated props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	orderUpdated,
	/**
	 * Fires a 'PageViewed' track call.
	 *
	 * @param PageViewed props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	pageViewed,
	/**
	 * Fires a 'ProductAdded' track call.
	 *
	 * @param ProductAdded props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	productAdded,
	/**
	 * Fires a 'ProductClicked' track call.
	 *
	 * @param ProductClicked props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	productClicked,
	/**
	 * Fires a 'ProductListViewed' track call.
	 *
	 * @param ProductListViewed props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	productListViewed,
	/**
	 * Fires a 'ProductViewed' track call.
	 *
	 * @param ProductViewed props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	productViewed,
	/**
	 * Fires a 'PromotionClicked' track call.
	 *
	 * @param PromotionClicked props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	promotionClicked,
	/**
	 * Fires a 'PromotionViewed' track call.
	 *
	 * @param PromotionViewed props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	promotionViewed,
	/**
	 * Fires a 'SegmentConsentPreferenceUpdated' track call.
	 *
	 * @param SegmentConsentPreferenceUpdated props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	segmentConsentPreferenceUpdated,
	/**
	 * Fires a 'SignupCompleted' track call.
	 *
	 * @param SignupCompleted props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	signupCompleted,
	/**
	 * Fires a 'SignupStarted' track call.
	 *
	 * @param SignupStarted props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	signupStarted,
	/**
	 * Fires a 'SignupStepCompleted' track call.
	 *
	 * @param SignupStepCompleted props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	signupStepCompleted,
	/**
	 * Fires a 'VideoThumbnailViewed' track call.
	 *
	 * @param VideoThumbnailViewed props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 	call is fired.
	 */
	videoThumbnailViewed
};

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI];
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
			const a = analytics();
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method
					},
					withTypewriterContext()
				);
			}
		};
	}
});
